import * as React from "react";
import CardClientes from "./cardClientes";
import { Grid2 } from "@mui/material";
import { useLocation } from "react-router-dom";
import Footer from "./footer";
import { db } from "../config/firebaseConfig";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";

export default function ListaC() {
  const location = useLocation();

  const title = location.state.title;
  const tipo = location.state.tipo;

  const [publicidad, setPublicidad] = React.useState([
    ]);

  useEffect(() => {
    const collectionRef = collection(db, "Publicidad");
    const q = query(
      collectionRef,
      where("lugar", "==", title),
      where("tipo", "==", tipo)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        console.log("Datos obtenidos");
        
      setPublicidad(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          cliente: doc.data().cliente,
          resumen: doc.data().resumen,
          detalles: doc.data().detalles,
          imagen: doc.data().imagen,
          estrellas: doc.data().estrellas,
          prioridad: doc.data().prioridad,
          abrido: doc.data().abrido,
          horario: doc.data().horario,
          direccion: doc.data().direccion,
          telefono: doc.data().telefono,
          galeria: doc.data().galeria,
        }))
      );
    });
    
    return unsubscribe;
  }, []);

  console.log(publicidad);

  return (

    
    <div style={{ display: 'flex', flexDirection: 'column' }} >

<header style={{
            display: "flex",
            padding: 5,
            alignSelf: "center",
            justifyContent: "center",
          }}>
        <img
          style={{
            height: 200,
            width: 400,
            marginTop: 50,
            position: "relative",
          }}
          src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLogos%2FLogo_transparente.png?alt=media&token=3165b93b-50ca-4b53-8a28-c61cdb1d679f"
        />
        <div className="example-container div">
          
        </div>
      </header>

      <div>

      <div
                style={{
                  display: "flex",
                  padding: 5,
                  alignSelf: "center",
                  justifyContent: "center",
                  color: "white",
                  
                }}
              >
                <h2 style={{ marginBottom: 5, alignSelf: "center" }}>
                  {title}
                </h2>
               
          </div>

      </div>

          
      
      <Grid2
        sx={{ padding: 5, borderRadius: 10, alignSelf: 'center', margin: 10, display: 'flex', flexDirection: 'row'}}
        container
        spacing={3}
        columns={32}
      >
        {publicidad.map((item) => (
            <Grid2 sx={{ alignSelf: 'center' }} size={16}>
                <CardClientes cliente={item.cliente} imagen={item.imagen} resumen={item.resumen} detalles={item.detalles} />
            </Grid2>
        ))}
      </Grid2>
      <Footer />
    </div>
  );
}
